
.header {
   position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
   
    &.active {
        background: rgba(255,255,255,0.15);
        backdrop-filter: blur(10px);
    }
}
.Nav_container {
    position: relative;
    max-width: 1224px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 2rem;
    margin-left: auto;
    margin-right: auto;
   .logo {
        background: #fff;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
   }
   .social_icons {
    display: flex;
       @media screen and (max-width: 800px) {
        display: none;
       }
       div {
           margin-left: 1rem;

        svg {
        cursor: pointer;
        color: #fff;
        opacity: 0.6;
        transition: 0.3s;
        z-index: 5;
        
        &:hover {
            opacity: 1;
        }
       }
       
     }
   }
   
   .menu {
    background: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    cursor: pointer;

    @media screen and (max-width: 800px) {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        width: 70%;
        height: 70%
    }
   }
   
   .closeMenu {
    background: #000;
    width: 4rem;
    height: 4rem;
    position: fixed;
    right: 0;
    top: 0;
    border-radius: 50%;
   }
   .menuX {
    color: #fff;
    position: fixed;
    top: 2rem;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    
    svg {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 2rem;
        right: 5rem;
    }
    li {
        list-style: none;
        margin-top: 1rem;

        a {
            text-decoration: none;
            text-transform: uppercase;
            color: #fff;
            font-size: 1.5rem;
            opacity: 0.6;
            transition: all 0.3s ease;
            &:hover {
                opacity: 1;
            }
        }
    }
   }
}
.nav_links {
    display: flex;
    list-style: none;


    li {
        margin-left: 3rem;

        @media screen and (max-width: 800px){
            display: none;
        }

        a {
            text-decoration: none;
            transition: 0.3s;
            color: #fff;
            text-transform: uppercase;
            font-size: 0.85rem;
            opacity: 0.6;
            &:hover {
               opacity: 1;
            }
        }
    }
}