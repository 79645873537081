.contact_form {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    background: #fff;
    padding: 2rem;
    border-radius: 15px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    gap: 2rem;
    overflow: hidden;

    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
    }

    .contact_left_container {
        h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        .contact_text {
            font-size: 0.85rem;
            margin-bottom: 1rem;

        }
        .contact_left {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            .icon {
            width: 50px;
            height: 50px;
            background: #8c12f0;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-right: 1rem;
        }
           p {
            font-size: 0.85rem;
           }
        }
        .social_icons {
            display: flex;
            margin-top: 1rem;

            div {
                margin-right: 1rem;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }

                svg {
                    font-size: 1.3rem;
                }
            }
        }

        
    }
    .contact_right {
        h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
        .row {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px,1fr));
            gap: 0.5rem;

            input {
                border: none;
                outline: none;
                margin-bottom: 0.5rem;
                padding: 1rem 1.5rem;
                background: #f3f3f3;
                border-radius: 5px;
                font-size: 0.85rem;
                font-family: "Poppins";
            }
            textarea {
                border: none;
                outline: none;
                margin-bottom: 0.5rem;
                padding: 1rem 1.5rem;
                background: #f3f3f3;
                border-radius: 5px;
                resize: none;
                min-height: 150px;
                font-size: 0.85rem;
                font-family: "Poppins";
            }
        }

        .btn {
            margin-top: 1rem;
            background: #8c12f0;
            padding: 0.65rem 1rem;
            border-radius: 10px;
            font-size: 0.85rem;
            width: 4.2rem;
        }
        a {
            text-decoration: none;
            color: #fff;
            text-transform: capitalize;
        }
    }
}