.about_container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    place-items: center;
    position: relative;

    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
    }

    .about_left {
        background: #8c12f0;
        max-width: 350px;
        height: 350px;
        justify-self: center;
        border-radius: 5px;
        box-shadow: 0 0 20px #8c12f0;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        
    }
    .about_right {
        color: #fff;
        padding: 1rem;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 700px) {
            padding: 0;
            margin-top: 2rem;
        }
        
        p {
            font-size: 0.85rem;
            margin-bottom: 1rem;max-width: 450px;
        }
        .bio {
            display: flex;
            font-size: 0.85rem;
            max-width: 200px;
            margin-bottom: 1rem;

            .bioKey {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                margin-right: 3rem;
            }
        }
        a {
            
                text-decoration: none;
                color: #000;
                background: #fff;
                padding: 0.65rem 1rem;
                border-radius: 10px;
                font-size: 0.85rem;
                text-transform: capitalize;
                width: 165px;
        }
    }
    
}